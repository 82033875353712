(function ( store, config ) {
    if ( ( 'firmowy' === config.creditType ) || ( 'selected-offer-business' === store.getItem( 'offer-type' ) && 'indywidualny' !== config.creditType ) ) {
        clearClasses();
        document.body.classList.add( 'selected-offer-business' );
        document.body.style.setProperty( '--offer-color', links[2].dataset.color );
        document.body.style.setProperty( '--offer-color-hover', links[2].dataset['color-hover'] || links[1].dataset.color );
        document.querySelectorAll( 'a[href="/wniosek/"]' ).forEach( function ( link ) {
            link.setAttribute( 'href', '/wniosek-firmowy/' );
        } );
    }

    function clearClasses () {
        document.body.classList.remove( 'selected-offer-personal' );
        document.body.classList.remove( 'selected-offer-business' );
    }
}( window.sessionStorage, HabzaCreditTypeConfig ));